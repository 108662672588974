import { inject, reactive } from "vue";
import Segments from "@/modules/Segments";
import { useRoute } from 'vue-router';
const { ProductNotify } = Segments();


export default function Notifications() {
    const axios = inject('axios');
    const route = useRoute();
    const notification = reactive({
        loading: false,
        error: '',
        data: [],
        count: 0,
    });
    const getNotifications = async() => {
        if (axios.isLoggedIn) {
            notification.loading = true;
            axios.authApi.get("/notifications")
                .then((response) => {
                    if (response.data.status) {
                        notification.data = (response.data.data.notifications)
                        notification.count = response.data.data.count
                    } else {
                        notification.error = response.data.error;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                }).finally(() => (notification.loading = false));
        }
    };

    const notify = reactive({
        loading: false,
        error: '',
        data: [],
    });
    const notifyme = async(variant,pro,type=null) => {
        if (axios.isLoggedIn) {
            notify.loading = true;
            axios.authApi.post("/notify", {
                    variant_id: variant.id
                }).then((resp) => {
                    if (resp.data.status) {
                        notify.data = resp.data.data;
                        ProductNotify('add',route,variant,pro,type);
                    } else {
                        notify.error = resp.data.error;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                }).finally(() => (notify.loading = false));
        }
    };
    const deleteNotify = async(variant,pro,type=null) => {
        if (axios.isLoggedIn) {
            notify.loading = true;
            axios.authApi.post("/delete-notify", {
                    variant_id: variant.id
                }).then((resp) => {

                    if (resp.data.status) {
                        notify.data = resp.data.data;
                        ProductNotify('remove',route,variant,pro,type);
                    } else {
                        notify.error = resp.data.error;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                }).finally(() => (notify.loading = false));
        }
    };
    return {
        notification,
        getNotifications,
        notify,
        notifyme,
        deleteNotify
    }
}